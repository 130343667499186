define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "account.logout": "Logout",
    "app.name": "NEN Connect",
    "appTapBar": {
      "labels": {
        "account": "Account",
        "collections": "Collections",
        "documents": "Documents",
        "home": "Home"
      }
    },
    "application-error": {
      "auth": {
        "description": "Something went wrong. Please logout or contact a administrator.",
        "link-label": "Reload page",
        "title": "Authentication error"
      },
      "internal-server": {
        "description": "Something went wrong. Please wait and retry or contact a administrator.",
        "link-label": "Logout to retry",
        "title": "Internal server error"
      }
    },
    "cancel": "Cancel",
    "collections.index.companyCollections": "Company documents",
    "collections.index.downloadCollections": "Downloadcollections",
    "collections.index.notificationCollections": "Notification collections",
    "collections.index.subcollections": "Subcollections",
    "connection": {
      "offline": {
        "go-offline": "To offline mode",
        "title": "Connection lost"
      },
      "restored": {
        "description": "Connection to the server has been lost.",
        "title": "Connection restored"
      }
    },
    "dashboard": {
      "recently-viewed": "Recent viewed"
    },
    "direction": {
      "next": "next",
      "prev": "previous"
    },
    "document": {
      "crow-links": "Links",
      "current-version": "Current version",
      "currentEdition": "Current edition",
      "drm-secured": "DRM secured",
      "editions": "Editions",
      "freely-available": "Freely available",
      "legislation-and-regulations": "Legislation and regulations",
      "links": "Links",
      "references": "References",
      "retractedAlert": {
        "description": "but may still be valid in other European countries because the Date of Withdrawal of the replacement standard has not yet been reached{replacedBy}.",
        "title": "This norm is retracted"
      },
      "standardRelations": {
        "amendedBy": "This document is retracted and amended by",
        "correctByLabel": "Correction",
        "correctedBy": "This document is corrected by",
        "replaces": "This document replaces",
        "replacesBy": "This document is replaced by"
      },
      "summary": "Summary",
      "web-viewer": "Web viewer",
      "with-notes": "With note",
      "withdrawn": "Withdrawn version"
    },
    "document-article": {
      "from-to": "Applies from {fromDate} until {toDate}",
      "present": "present"
    },
    "document-change": {
      "meta": {
        "changeDate": "Change date",
        "changedBy": "Changed by",
        "icsCode": "ICS-code",
        "inCollection": "In your collection",
        "publicationDate": "Publication date",
        "reasonForChange": "Reason for change",
        "standardsCommittee": "Standards committee",
        "technicalCommittee": "Technical committee"
      },
      "status": {
        "concept": "Draft",
        "current": "Current",
        "definitive": "Definitive",
        "draft": "Draft",
        "withdrawn": "Withdrawn"
      }
    },
    "document-html-view": {
      "model": {
        "error": {
          "description": "Contact your administrator for help.",
          "title": "Unable to load document html view"
        }
      },
      "remark": {
        "delete": "Delete",
        "edit": "Edit",
        "private": "Private remark (only visible to myself)"
      },
      "section": {
        "not-found": {
          "description": "No chapter or section found for this url. {actionText}",
          "title": "Chapter or section not found.",
          "to-chapter": "Redirecting to current chapter.",
          "to-first-pagine": "Redirecting to first chapter of document."
        }
      }
    },
    "documentCard": {
      "harmonized": "Harmonized",
      "hideAdditionsAndCorrections": "Hide additions & corrections",
      "showAdditionsAndCorrections": "Show additions & corrections"
    },
    "documents": {
      "companyFavorites": {
        "added": {
          "description": "Document \"{name}\" is added to your company favorites",
          "title": "Favorite added"
        },
        "removed": {
          "description": "Document \"{name}\" is removed from your company favorites",
          "title": "Favorite removed"
        }
      },
      "downloads": {
        "add": {
          "description": "Document successfully added to downloaded documents.",
          "error": {
            "description": "Check if this account has permission to download the document.",
            "title": "Download failed"
          },
          "title": "Document downloaded"
        },
        "removed": {
          "description": "Document successfully removed from offline documents.",
          "title": "Document removed"
        },
        "viewPdf": {
          "loading": {
            "description": "PDF is being downloaded.",
            "title": "PDF download"
          }
        }
      },
      "favorites": {
        "added": {
          "description": "Document \"{name}\" is added to your favorites",
          "title": "Favoriet added"
        },
        "removed": {
          "description": "Document \"{name}\" is removed from your favorites",
          "title": "Favorite removed"
        }
      },
      "index": {
        "compact_view_label": "View",
        "filter_label": "Filters"
      }
    },
    "editionCard": {
      "hideVersions": "Hide versions",
      "showVersions": "Show versions"
    },
    "emptyState": {
      "clearSearch": "Clear search",
      "downloads": "No downloaded documents",
      "noDownloadsText": "You have no downloaded documents on this device. ",
      "noRequests": "No {state} {type} requests found.",
      "noResultsText": "We cannot find any documents with the current search query and selected filters. Try another search query or",
      "request": {
        "documents": {
          "closed": "No closed document requests.",
          "open": "No new document requests."
        },
        "licenses": {
          "closed": "No closed license requests.",
          "open": "No new license requests."
        }
      },
      "resetFilters": "Reset filters",
      "results": "{count} results",
      "toDocuments": "Online documents"
    },
    "filters": {
      "favorites": "Favorites",
      "other": "Other"
    },
    "html-view": {
      "remark": {
        "delete": "Delete",
        "edit": "Edit"
      }
    },
    "language-switch.title": "Language / Taal",
    "languages.en-us": "EN",
    "languages.nl-nl": "NL",
    "legislation": {
      "expired-legislations": {
        "description": "Support from NEN lasts until january 1 2020.",
        "title": "Invalid legislations and regulations"
      }
    },
    "licenses": {
      "active": "Active",
      "added": "Added",
      "company_documents": "Company documents",
      "deleted": "Deleted",
      "licenseNumber": "license no.",
      "licenses": "Licenses",
      "not_active": "Inactive",
      "notification_collection": "Notification collection",
      "standards": "Standards",
      "status": "Status",
      "used": "Used",
      "used-mbs": "{mbs} MBs",
      "users": "Users"
    },
    "login-form.buttons.login": "Login",
    "login-form.buttons.next": "Next",
    "login-form.labels.email": "Email",
    "login-form.labels.password": "Password",
    "login-form.placeholders.email": "Email",
    "login-form.placeholders.password": "Password",
    "modelName": {
      "standard": {
        "plural": "Standards",
        "single": "Standard"
      }
    },
    "notifications": {
      "active": {
        "description": "You will receive a popup asking to turn on notifications.",
        "title": "Turn on notifications"
      },
      "concent": {
        "label": "Accept notification "
      },
      "suffix": "What notifications would you like to receive?"
    },
    "offline_available": "Offline available",
    "onboarding": {
      "install-pwa": {
        "add-to-homescreen": {
          "confirm": {
            "label": "Add now"
          },
          "description": "View documents offline, receive important notifications and easily access the app.",
          "title": "Add to home screen"
        },
        "confirm-install": {
          "label": "Yes, I have added it"
        },
        "current-step": "Step {current} of {max}"
      },
      "install-pwa-step": {
        "confirm-label": "Add now"
      },
      "interface": {
        "add": "Add",
        "add-to-home": "Add to home",
        "share": "Share"
      },
      "notifications-concent-step": {
        "description": "Want to always be up date? Turn on notifications.",
        "title": "Notifications"
      },
      "share": "Share",
      "skip": {
        "label": "Setup later"
      },
      "substeps": {
        "step-1": {
          "chrome": {
            "description": "In chrome you can find \"{share}\" at the top right corner of your browser."
          },
          "safari": {
            "description": "In safari you can find it in the bottom center of your browser."
          },
          "title": "First click \"{share}\""
        },
        "step-2": {
          "description": "Is \"{addToHome}\" not visible? You might have to scroll down.",
          "title": "Click add to \"{addToHome}\""
        },
        "step-3": {
          "description": "When you click {add} the app will be on your homescreen.",
          "title": "To confirm click \"{add}\""
        }
      }
    },
    "page-header": {
      "documents": "Documents",
      "downloaded-documents": "Downloaded documents",
      "recently-changed": "Recently changed"
    },
    "page-title.documents": "Documents",
    "page-title.login": "Login",
    "page-title.notifications": "Notifications",
    "page-title.offline.documents.archive": "Offline available",
    "page_description.collections": "Collections",
    "page_headers.collection": "Collection",
    "page_headers.collections": "Collections",
    "pagination": {
      "Of": "of",
      "Page": "Page"
    },
    "period-filter-label": "Period",
    "period-filter-option": {
      "month": "Last month",
      "quarter": "Last quarter",
      "week": "Last week",
      "year": "Last year"
    },
    "profile": {
      "company": "Company",
      "email": "E-mail",
      "name": "Name",
      "role": "Role"
    },
    "quick_links": {
      "licenses": "Licenses",
      "my_data": "My profile",
      "my_favorites": "My favorites",
      "notifications": "Notifications",
      "offline_available": "Offline available",
      "recent_changed": "Recent changed",
      "requests": "Requests"
    },
    "recently-changed": {
      "email-notification": {
        "description": "Here you can adjust the settings for the changes you will receive via email.",
        "error": {
          "email-fields": {
            "at-least-two": "Besides document you will need to select at least 1 aditional option to receive in the html-mail.",
            "document-required": "Document is required",
            "to-many-options": "Besides document number you can choose max. 3 additional options to receive in the html-mail."
          }
        },
        "html": {
          "description": "You can select up to 3 options in addition to the document number to receive in the HTML-mail.",
          "title": "Selection"
        },
        "parse-error": "Unable to select, selected option is invalid",
        "request-error": "Unable to update settings",
        "save-label": "Save",
        "title": "Email notification",
        "toggle-label": "Turn on/off",
        "type": {
          "csv": "All as CSV",
          "html": "Selection from HTML"
        }
      }
    },
    "recently-changed-filter": {
      "all": "All",
      "favorites": "Favorites",
      "free_available": "Free available",
      "my_collection": "My collections",
      "my_sub_collection": "My subcollections"
    },
    "recently-changed-filter-label": "Show",
    "recently-changed-period-label": "Period",
    "recently-changed-period-option": {
      "custom": "Custom",
      "last_month": "Last month",
      "last_quarter": "Last quarter",
      "last_week": "Last week",
      "last_year": "Last year"
    },
    "remark": {
      "count": "{count} remarks",
      "create": {
        "failed": "Remark could not be created",
        "submit-label": "Submit",
        "succes": "Remark successfully created",
        "title": "Create remark"
      },
      "date": {
        "unknown": "Date unknown"
      },
      "delete": {
        "confirm": "You are about to delete this remark, are you sure?",
        "failed": "Remark could not be deleted",
        "succes": "Remark successfully deleted"
      },
      "edit": {
        "failed": "Remark could not be edited",
        "submit-label": "Submit",
        "succes": "Remark successfully edited",
        "title": "Edit remark"
      },
      "input": {
        "error": {
          "default": "Invalid remark",
          "empty-text": "A remark cannot be empty",
          "invalid-text": "Remark is invalid",
          "unchanged-text": "Remark is unchanged"
        },
        "label": "Remark"
      }
    },
    "request": {
      "access": {
        "cancel": "Cancel",
        "request": "Send request"
      },
      "approve": {
        "succes": {
          "title": "Approved"
        },
        "title": "Approve request",
        "title_short": "Approve"
      },
      "closed": "Closed",
      "collection": "Collection",
      "decline": {
        "succes": {
          "description": "Request successfully declined.",
          "title": "Request decline successfull."
        },
        "title": "Decline request",
        "title_short": "Decline"
      },
      "document": "Document",
      "documents": "Documents",
      "download-collection": {
        "description": "U have {count} additions left in downloadcollection.",
        "title": "Downloadcollection"
      },
      "error": {
        "approve": {
          "description": "Request error",
          "title": "Something went wrong"
        },
        "decline": {
          "description": "Failed to decline request.",
          "title": "Request decline failed."
        },
        "no-collection": {
          "description": "No collection found",
          "title": "Something went wrong"
        },
        "no-id": {
          "description": "No valid standard was selected to approve.",
          "title": "No valid standard selected."
        }
      },
      "license": "License",
      "licenses": "Licenses",
      "new": "New",
      "notification-collection": {
        "description": "U have {count} additions left in notificationcollection.",
        "title": "Notificationcollection"
      },
      "owner": "Owner",
      "period": {
        "lastWeek": "Last week",
        "pastMonth": "Past month",
        "pastQuarter": "Past quarter",
        "pastTerm": "Past term",
        "pastWeek": "Past week",
        "pastYear": "Past year",
        "period": "Period"
      },
      "reason": "Reason",
      "requestReason": "Request reason",
      "requested": "Requested",
      "sender": "Sender",
      "standard": {
        "collections": {
          "error": {
            "title": "Failed to load request panel"
          }
        },
        "error": {
          "title": "Request failed to send"
        },
        "success": {
          "title": "Request successfully send"
        },
        "title": "Request standard"
      },
      "standards": "Standards"
    },
    "search": "Search",
    "searchbar": {
      "SearchDocument": "Search document"
    },
    "slide-in": {
      "add-standard-to-collection": {
        "add-to-collection": "Add to collection",
        "cancel": "Cancel",
        "currentCollection": "Current collections",
        "default-option": "- Select a collection -",
        "description": "Add to collection",
        "selected-collection-standard-count-description": "You can add {count} standards to the {collectionName}",
        "title": "Add standard to collection"
      },
      "close-label": "Close",
      "document-filters": {
        "loading": "Loading...",
        "reset-filters": "Reset filters",
        "show-results": "Show {count} results",
        "title": "Filters"
      },
      "request": {
        "add-to-collection": {
          "label": "Add to collection"
        },
        "reason": {
          "error": "Please add a request reason.",
          "label": "Request reason"
        }
      }
    },
    "standard": {
      "AddToCollection": "Add to collection",
      "ChangeCollection": "Change collection",
      "add-to-collection": "Add to collection",
      "addToCollection": {
        "error": "Standard failed to be added to collection.",
        "succes": "Standard successfully added to collection."
      },
      "change-collection": "Change collection",
      "collection": {
        "no-access": "This standard is not yet available in your collection"
      },
      "index": {
        "currentEdition": "Current edition",
        "deprecatedEditions": "Other editions",
        "legislationAndRegulation": "Legislation and regulations"
      },
      "no-selected-collection": "No collection is selected.",
      "removeFromCollection": {
        "error": "Standard failed to be removed from collection.",
        "succes": "Standard successfully removed from collection."
      },
      "request-standard": "Request standard",
      "title": "Standard"
    },
    "standardCard": {
      "AllEditions": "All editions",
      "currentEdition": "Current edition",
      "hideEdition": "Hide editions",
      "showEdition": "Show editions"
    },
    "toc": {
      "navigation": {
        "error": {
          "description": "Navigation to section failed because of a error.",
          "title": "Unable to navigate to {direction} section."
        }
      }
    },
    "user_role": {
      "AccountManager": "Account manager",
      "CompanyAdministrator": "Company Administrator",
      "Expert": "Expert",
      "FunctionalAdmin": "Functional Admin",
      "IpUser": "Ip User",
      "NenAdministrator": "NEN-Administrator",
      "ProductAdmin": "Product Admin",
      "ServiceProvider": "Service Provider",
      "User": "User"
    },
    "validations": {
      "format": {
        "email": "Please enter a valid email address",
        "invalid": "Invalid e-mail or password"
      }
    },
    "validations.format.email": "Email is not valid",
    "validations.format.invalid": "Invalid email or password",
    "welcome": "Welcome"
  }], ["nl-nl", {
    "account.logout": "Uitloggen",
    "app.name": "NEN Connect",
    "appTapBar": {
      "labels": {
        "account": "Account",
        "collections": "Collecties",
        "documents": "Documenten",
        "home": "Thuis"
      }
    },
    "application-error": {
      "auth": {
        "description": "Iets ging verkeerd. Log uit of neem contact op met een administrator.",
        "link-label": "Log uit om opnieuw te proberen",
        "title": "Authenticatie error"
      },
      "internal-server": {
        "description": "Er is iets fout gegaan. \nWacht en probeer het opnieuw of neem contact op met een beheerder.",
        "link-label": "Meld u af om het opnieuw te proberen",
        "title": "Interne Server Fout"
      }
    },
    "cancel": "Annuleren",
    "collections.index.companyCollections": "Bedrijfsdocumenten",
    "collections.index.downloadCollections": "Downloadcollecties",
    "collections.index.notificationCollections": "Attenderingcollecties",
    "collections.index.subcollections": "Subcollecties",
    "connection": {
      "offline": {
        "go-offline": "Naar offline modes",
        "title": "Verbinding verbroken"
      },
      "restored": {
        "description": "Verbinding met de server is verbroken.",
        "title": "Verbinding hersteld"
      }
    },
    "dashboard": {
      "recently-viewed": "Recent bekeken"
    },
    "direction": {
      "next": "volgende",
      "prev": "vorige"
    },
    "document": {
      "crow-links": "Koppelingen",
      "current-version": "Actuele versie",
      "currentEdition": "Huidige editie",
      "drm-secured": "DRM beveiligd",
      "editions": "Edities",
      "freely-available": "Vrij beschikbaar",
      "legislation-and-regulations": "Wet- en regelgeving",
      "links": "Koppelingen",
      "references": "Verwijzingen",
      "retractedAlert": {
        "description": "maar mogelijk nog actueel in andere Europese landen omdat de Date of Withdrawal van de vervangende norm nog niet bereikt is{replacedBy}.",
        "title": "Deze norm is ingetrokken"
      },
      "standardRelations": {
        "amendedBy": "Dit document is ingetrokken en vervangen door",
        "correctByLabel": "Correctie",
        "correctedBy": "Dit document is gewijzigd door",
        "replaces": "Dit document vervangt",
        "replacesBy": "Dit document is vervangen door"
      },
      "summary": "Samenvatting",
      "web-viewer": "Web viewer",
      "with-notes": "Met notitie",
      "withdrawn": "Ingetrokken versie"
    },
    "document-article": {
      "from-to": "Geldig van {fromDate} tot {toDate}",
      "present": "heden"
    },
    "document-change": {
      "meta": {
        "changeDate": "Wijzigingsdatum",
        "changedBy": "Gewijzigd door",
        "icsCode": "ICS-code",
        "inCollection": "In uw verzameling",
        "publicationDate": "Publicatiedatum",
        "reasonForChange": "Reden van wijziging",
        "standardsCommittee": "Norm commissie",
        "technicalCommittee": "Technische commissie"
      },
      "status": {
        "concept": "Concept",
        "current": "Huidig",
        "definitive": "Definitief",
        "draft": "Voorlopige versie",
        "withdrawn": "Ingetrokken"
      }
    },
    "document-html-view": {
      "model": {
        "error": {
          "description": "Neem contact op met u administrator voor hulp.",
          "title": "Document HTML weergaven kon niet worden geladen."
        }
      },
      "remark": {
        "delete": "Verwijder",
        "edit": "Bewerk",
        "private": "Prive notitie (alleen voor mij zichtbaar)"
      },
      "section": {
        "not-found": {
          "description": "Er kon geen hoofdstuk of paragraaf gevonden worden voor de huidige url. {actionText}",
          "title": "Hoofdstuk of paragraaf niet gevonden.",
          "to-chapter": "Omleiden naar huidig hoofdstuk.",
          "to-first-pagine": "Omleiden naar het eerste hoofdstuk van dit document."
        }
      }
    },
    "documentCard": {
      "harmonized": "Geharmoniseerd",
      "hideAdditionsAndCorrections": "Verberg aanvullingen & correcties",
      "showAdditionsAndCorrections": "Toon aanvullingen & correcties"
    },
    "documents": {
      "companyFavorites": {
        "added": {
          "description": "Document \"{name}\" is toegevoegd aan uw bedrijfsfavorieten",
          "title": "Favoriet toegevoegd"
        },
        "removed": {
          "description": "Document \"{name}\" is verwijderd uit uw bedrijfsfavorieten",
          "title": "Favoriet verwijderd"
        }
      },
      "downloads": {
        "add": {
          "description": "Document succesvol toegevoegd aan offline documenten.",
          "error": {
            "description": "Controlleer of het huidige account voldoende rechten heeft.",
            "title": "Download afgebroken"
          },
          "title": "Document gedownload"
        },
        "removed": {
          "description": "Document succesvol verwijderd uit offline documenten.",
          "title": "Document verwijderd"
        },
        "viewPdf": {
          "loading": {
            "description": "PDF wordt gedownload.",
            "title": "Pdf-download"
          }
        }
      },
      "favorites": {
        "added": {
          "description": "Document \"{name}\" is toegevoegd aan uw favorieten",
          "title": "Favoriet toegevoegd"
        },
        "removed": {
          "description": "Document \"{name}\" is verwijderd uit uw favorieten",
          "title": "Favoriet verwijderd"
        }
      },
      "index": {
        "compact_view_label": "Weergave",
        "filter_label": "Filters"
      }
    },
    "editionCard": {
      "hideVersions": "Verberg versies",
      "showVersions": "Toon versies"
    },
    "emptyState": {
      "clearSearch": "Wis zoekopdracht",
      "downloads": "Geen gedownloade documenten",
      "noDownloadsText": "U heeft geen gedownloade documenten.",
      "noRequests": "Geen {state} {type} verzoeken gevonden.",
      "noResultsText": "Met de huidige zoekopdracht en geselecteerde filters kunnen we geen documenten vinden. Probeer een andere zoekopdracht of",
      "request": {
        "documents": {
          "closed": "Geen gesloten documentaanvragen.",
          "open": "Geen nieuwe documentaanvragen."
        },
        "licenses": {
          "closed": "Geen gesloten licentieaanvragen.",
          "open": "Geen nieuwe licentieaanvragen."
        }
      },
      "resetFilters": "Reset filters",
      "results": "{count} resultaten",
      "toDocuments": "Online documenten"
    },
    "filters": {
      "favorites": "Favorieten",
      "other": "Overige"
    },
    "html-view": {
      "remark": {
        "delete": "Verwijder",
        "edit": "Bewerken"
      }
    },
    "language-switch.title": "Taal / Language",
    "languages.en-us": "EN",
    "languages.nl-nl": "NL",
    "legislation": {
      "expired-legislations": {
        "description": "De relatie tussen wetgeving en normen op NEN Connect wordt onderhouden per 1 januari 2020. Voor wetgeving vóór 1 januari 2020 kunt u kijken op www.wetten.overheid.nl",
        "title": "Niet meer geldig in wetgeving"
      }
    },
    "licenses": {
      "active": "Actief",
      "added": "Toegevoegd",
      "company_documents": "Bedrijfsdocumenten",
      "deleted": "Verwijderd",
      "licenseNumber": "licentie nr.",
      "licenses": "Licenties",
      "not_active": "Niet actief",
      "notification_collection": "Attendering",
      "standards": "Normen",
      "status": "Status",
      "used": "Gebruikt",
      "used-mbs": "{mbs} MBs",
      "users": "Gebruikers"
    },
    "login-form.buttons.login": "Inloggen",
    "login-form.buttons.next": "Volgende",
    "login-form.labels.email": "E-mailadres",
    "login-form.labels.password": "Wachtwoord",
    "login-form.placeholders.email": "E-mailadres",
    "login-form.placeholders.password": "wachtwoord",
    "modelName": {
      "standard": {
        "plural": "Normen",
        "single": "Norm"
      }
    },
    "notifications": {
      "active": {
        "description": "U krijgt een melding om notificaties aan te zetten.",
        "title": "Notificaties aanzetten"
      },
      "concent": {
        "label": "Notificaties aanzetten"
      },
      "suffix": "Welke meldingen wilt u ontvangen?"
    },
    "offline_available": "Offline beschikbaar",
    "onboarding": {
      "install-pwa": {
        "add-to-homescreen": {
          "confirm": {
            "label": "Nu toevoegen"
          },
          "description": "Bekijk documenten offline, ontvang belangrijke notificaties en vind de app makkelijk terug.",
          "title": "Toevoegen aan home screen"
        },
        "confirm-install": {
          "label": "Ja, ik heb hem toegevoegd"
        },
        "current-step": "Stap {current} van {max}"
      },
      "install-pwa-step": {
        "confirm-label": "Nu toevoegen"
      },
      "interface": {
        "add": "Voeg toe",
        "add-to-home": "Zet op startscherm",
        "share": "Delen"
      },
      "notifications-concent-step": {
        "description": "Altijd op de hoogte van wat er gebeurt? Zet dan notificaties aan. Wilt u deze nu aanzetten?",
        "title": "Notificaties"
      },
      "share": "Delen",
      "skip": {
        "label": "Later instellen"
      },
      "substeps": {
        "step-1": {
          "chrome": {
            "description": "In Chrome vindt u de “{share}” optie rechtsboven in de adresbalk."
          },
          "safari": {
            "description": "In Safari vindt u die onderin de browser terug als het middelste icoon."
          },
          "title": "Klik eerst op “{share}”"
        },
        "step-2": {
          "description": "Staat “{addToHome}” niet in beeld? Dan kan het zijn dat u moet scrollen.",
          "title": "Klik dan op “{addToHome}”"
        },
        "step-3": {
          "description": "Zodra je in het volgende scherm op “{add}” klikt staat de app op uw home screen.",
          "title": "Klik tot slot op “{add}”"
        }
      }
    },
    "page-header": {
      "documents": "Documenten",
      "downloaded-documents": "Gedownloade documenten",
      "recently-changed": "Onlangs bijgewerkt"
    },
    "page-title.documents": "Documenten",
    "page-title.login": "Inloggen",
    "page-title.notifications": "Notificaties",
    "page-title.offline.documents.archive": "Offline beschikbaar",
    "page_description.collections": "Een overzicht van uw documenten",
    "page_headers.collection": "Collectie",
    "page_headers.collections": "Collecties",
    "pagination": {
      "Of": "van",
      "Page": "Pagina"
    },
    "period-filter-label": "Periode",
    "period-filter-option": {
      "month": "Vorige maand",
      "quarter": "Laatste kwartaal",
      "week": "Vorige week",
      "year": "Afgelopen jaar"
    },
    "profile": {
      "company": "Bedrijf",
      "email": "E-mail",
      "name": "Naam",
      "role": "Rol"
    },
    "quick_links": {
      "licenses": "Licenties",
      "my_data": "Mijn gegevens",
      "my_favorites": "Mijn favorieten",
      "notifications": "Notificaties",
      "offline_available": "Offline beschikbaar",
      "recent_changed": "Recent gewijzigd",
      "requests": "Aanvragen"
    },
    "recently-changed": {
      "email-notification": {
        "description": "Hier kunt de instellingen aanpassen van de wijzigingen die u via e-mail zult ontvangen.",
        "error": {
          "email-fields": {
            "at-least-two": "Naast het document moet je minstens 1 extra optie selecteren om te ontvangen in de HTML-email.",
            "document-required": "Document is vereist",
            "to-many-options": "Naast het documentnummer kun je maximaal 3 extra opties kiezen om in de html-mail te ontvangen."
          }
        },
        "html": {
          "description": "U kunt naast documentnummer maximaal 3 opties kiezen om in de html-mail te ontvangen.",
          "title": "Selectie"
        },
        "parse-error": "Unable to select, selected option is invalid",
        "request-error": "Instellingen kunnen niet worden bijgewerkt",
        "save-label": "Opslaan",
        "title": "Email notificatie",
        "toggle-label": "In-/uitschakelen",
        "type": {
          "csv": "Alles in CSV",
          "html": "Selectie in HTML"
        }
      }
    },
    "recently-changed-filter": {
      "all": "Alle",
      "favorites": "Favorieten",
      "free_available": "Vrij beschikbaar",
      "my_collection": "Mijn collecties",
      "my_sub_collection": "Mijn subcollecties"
    },
    "recently-changed-filter-label": "Toon",
    "recently-changed-period-label": "Periode",
    "recently-changed-period-option": {
      "custom": "Aangepast",
      "last_month": "Vorige maand",
      "last_quarter": "Laatste kwartaal",
      "last_week": "Vorige week",
      "last_year": "Afgelopen jaar"
    },
    "remark": {
      "count": "{count} notities",
      "create": {
        "failed": "Notitie kon niet worden aangemaakt",
        "submit-label": "Bevestig",
        "succes": "Notitie succesvol aangemaakt",
        "title": "Notitie aanmaken"
      },
      "date": {
        "unknown": "Datum onbekend"
      },
      "delete": {
        "confirm": "Je staat op het punt om deze notitie te wissen, weet je dit zeker?",
        "failed": "Notitie kon niet verwijderd worden",
        "succes": "Notitie succesvol verwijderd"
      },
      "edit": {
        "failed": "Notitie kon niet worden aangepast",
        "submit-label": "Bevestig",
        "succes": "Notitie succesvol aangepast",
        "title": "Bewerk notitie"
      },
      "input": {
        "error": {
          "default": "Ongeldige notitie",
          "empty-text": "Een notitie mag niet leeg zijn",
          "invalid-text": "Notitie is ongeldig",
          "unchanged-text": "Notitie is onaangepast"
        },
        "label": "Notitie"
      }
    },
    "request": {
      "access": {
        "cancel": "Annuleren",
        "request": "Aanvragen"
      },
      "approve": {
        "succes": {
          "title": "Goedgekeurd"
        },
        "title": "Aanvraag goedkeuren",
        "title_short": "Goedkeuren"
      },
      "closed": "Gesloten",
      "collection": "Collectie",
      "decline": {
        "succes": {
          "description": "Afwijzing succesvol.",
          "title": "Aanvraag afwijzing succesvol"
        },
        "title": "Verzoek afwijzen",
        "title_short": "Afwijzen"
      },
      "document": "Document",
      "documents": "Documenten",
      "download-collection": {
        "description": "U heeft {count} toevoegingen over in de downloadcollectie.",
        "title": "Downloadcollectie"
      },
      "error": {
        "approve": {
          "description": "Er is een fout opgetreden tijdens het verzoek naar de server",
          "title": "Er is een fout opgetreden"
        },
        "decline": {
          "description": "Aanvraag kon niet afgewezen worden.",
          "title": "Afwijzing onsuccesvol."
        },
        "no-collection": {
          "description": "Geen collectie gevonden",
          "title": "Er is een fout opgetreden"
        },
        "no-id": {
          "description": "Er is geen geldige standaard geselecteerd om goed te keuren.",
          "title": "Geen geldige standaard geselecteerd."
        }
      },
      "license": "Licentie",
      "licenses": "Licensies",
      "new": "Nieuwe",
      "notification-collection": {
        "description": "U heeft nog {count} toevoegingen over in notificationcollection.",
        "title": "Meldingsverzameling"
      },
      "owner": "Eigenaar",
      "period": {
        "lastWeek": "Afgelopen week",
        "pastMonth": "Afgelopen maand",
        "pastQuarter": "Afgelopen kwartaal",
        "pastTerm": "Afgelopen termijn",
        "pastWeek": "Afgelopen week",
        "pastYear": "Afgelopen jaar",
        "period": "Periode"
      },
      "reason": "Reden",
      "requestReason": "Aanvraag reden",
      "requested": "Aangevraagd",
      "sender": "Afzender",
      "standard": {
        "collections": {
          "error": {
            "title": "Aanvraag panel kon niet worden geopend"
          }
        },
        "error": {
          "title": "Aanvraag kon niet verstuurd worden"
        },
        "success": {
          "title": "Aanvraag verstuurd"
        },
        "title": "Norm aanvragen"
      },
      "standards": "Normen"
    },
    "search": "Zoeken",
    "searchbar": {
      "SearchDocument": "Zoek document"
    },
    "slide-in": {
      "add-standard-to-collection": {
        "add-to-collection": "Voeg aan collectie toe",
        "cancel": "Annuleren",
        "currentCollection": "Huidige collecties",
        "default-option": "- Selecteer een collectie -",
        "description": "Voeg aan collectie toe",
        "selected-collection-standard-count-description": "U kunt nog {count} normen toevoegen aan de {collectionName}",
        "title": "Norm toevoegen aan collection"
      },
      "close-label": "Sluiten",
      "document-filters": {
        "loading": "Laden...",
        "reset-filters": "Wis filters",
        "show-results": "Toon {count} resultaten",
        "title": "Filters"
      },
      "request": {
        "add-to-collection": {
          "label": "Toevoegen aan collectie"
        },
        "reason": {
          "error": "Voeg een reden voor aanvragen toe.",
          "label": "Reden van de aanvraag"
        }
      }
    },
    "standard": {
      "AddToCollection": "Voeg toe aan collectie",
      "ChangeCollection": "Collectie wijzigen",
      "add-to-collection": "Toevoegen aan collectie",
      "addToCollection": {
        "error": "Standaard kon niet worden toegevoegd aan collectie.",
        "succes": "Standaard succesvol toegevoegd aan collectie."
      },
      "change-collection": "Collectie aanpassen",
      "collection": {
        "no-access": "Deze norm is helaas nog niet beschikbaar in uw collectie"
      },
      "index": {
        "currentEdition": "Huidige editie",
        "deprecatedEditions": "Overige edities",
        "legislationAndRegulation": "Wet en regelgeving"
      },
      "no-selected-collection": "Er is geen collectie geselecteerd.",
      "removeFromCollection": {
        "error": "Standaard kon niet worden verwijderd uit collectie.",
        "succes": "Standaard succesvol verwijderd uit collectie."
      },
      "request-standard": "Norm aanvragen",
      "title": "Norm"
    },
    "standardCard": {
      "AllEditions": "Alle edities",
      "currentEdition": "Verberg editie",
      "hideEdition": "Edities verbergen",
      "showEdition": "Edities tonen"
    },
    "toc": {
      "navigation": {
        "error": {
          "description": "Navigeren naar sectie niet geslaagd door een interne error.",
          "title": "Navigeren naar {direction} niet geslaagd."
        }
      }
    },
    "user_role": {
      "AccountManager": "Account manager",
      "CompanyAdministrator": "Bedrijfsbeheerder",
      "Expert": "Expert",
      "FunctionalAdmin": "Functioneel Admin",
      "IpUser": "Ip Gebruiker",
      "NenAdministrator": "NEN-Beheerder",
      "ProductAdmin": "Product Admin",
      "ServiceProvider": "Service Provider",
      "User": "Gebruiker"
    },
    "validations": {
      "format": {
        "email": "Vul een geldig e-mailadres in",
        "invalid": "Ongeldig e-mailadres of wachtwoord"
      }
    },
    "validations.format": {
      "email": "Vul een geldig e-mailadres in",
      "invalid": "Ongeldig e-mailadres of wachtwoord"
    },
    "validations.format.email": "Email is not valid",
    "validations.format.invalid": "Invalid email or password",
    "welcome": "Welcome"
  }]];
  _exports.default = _default;
});